import { ORDER, SORT } from "~/constants";
import useRequest from "~/hooks/use-request";
import { formatPaginatedResponseItems } from "../format";

const formatNavigationMenu = (data: IResponseNavigationMenu) => {
  const appConfig = useRuntimeConfig().public;
  const isEnableShortName = appConfig.APP_CUSTOMER?.enable_teams_short_name;

  return {
    id: data._id,
    title: data.title,
    teams: data.teams.map(team => ({ ...team, title: isEnableShortName && team.shortTitle ? team.shortTitle : team.title })),
  };
};

const NavigationMenuAPI = {
  load(options?: IRequestPaginatedParams) {
    const { request } = useRequest();

    const defaultParams = {
      page: 0,
      size: 50,
      order: ORDER.asc,
      sort: SORT.order,
    };

    const params = Object.assign({}, defaultParams, options);

    return request
      .get<IPaginatedResponse<IResponseNavigationMenu>>("/api/resources/navigation_menu", { query: params })
      .then(response => formatPaginatedResponseItems(response, formatNavigationMenu));
  },
};
export default NavigationMenuAPI;
